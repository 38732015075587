import { Injectable } from '@angular/core';
import { ApiResp, UserAccount } from '@app/types/global.type';
import { Observable, lastValueFrom, map } from 'rxjs';
import { BaseService } from './base.service';

export interface LoginResp {
  token: string;
  userEmail: string;
  userName: string;
  userId?: number;

  roleName?: string;
  userFeatures?: string[];

  orgDetails?: UserAccount[];
  passwordExpired?: boolean;
  isMfaEnable: boolean;
  isMfaUserTemporaryDisable?: boolean;
}

export interface AccessList {
  roleName: string;
  features: string[];
}

export interface ForgetPassword {
  userId: string;
  newPassword: string;
  otp: string;
}
export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
}

export interface EnableDisableMfa {
  userEmail: string;
  mfaEnable: boolean;
}

export interface VerifyMfaOtp {
  userId: string;
  otp: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  login(payload: {
    userId: string;
    password: string;
    token: string;
    client: string;
  }): Observable<LoginResp> {
    payload.client = 'UI';
    return this.httpClient.post('/auth/user/login', payload).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((resp: any) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  accessList(): Promise<AccessList | null> {
    const observable = this.httpClient
      .get<ApiResp>('/myportal/org/features')
      .pipe(map((resp) => resp.response as AccessList));
    return lastValueFrom(observable);
  }

  getOTP(otp: ForgetPassword): Observable<ApiResp> {
    return this.httpClient
      .post<ApiResp>('/auth/user/forgot/password', otp)
      .pipe(map((resp: ApiResp) => this.handleFormResp(resp)));
  }

  resetPassword(payload: ForgetPassword): Observable<ApiResp> {
    return this.httpClient
      .post<ApiResp>('/auth/user/reset/password', payload)
      .pipe(map((resp: ApiResp) => this.handleFormResp(resp)));
  }

  changePassword(payload: ChangePassword): Observable<ApiResp> {
    return this.httpClient
      .put<ApiResp>('/auth/user/change/password', payload)
      .pipe(map((resp: ApiResp) => this.handleFormResp(resp)));
  }

  enableDisableMfa(payload: EnableDisableMfa) {
    return this.httpClient
      .post<ApiResp>('/myportal/user/mfa/enable-disable', payload)
      .pipe(map((resp: ApiResp) => this.handleFormResp(resp)));
  }

  getOtpForMfa(userId: string) {
    return this.httpClient
      .post<ApiResp>('/auth/user/mfa/generate/otp', { userId })
      .pipe(map((resp: ApiResp) => this.handleFormResp(resp)));
  }

  verifyMfaOtp(payload: VerifyMfaOtp) {
    return this.httpClient
      .post<ApiResp>('/auth/user/mfa/verify/otp', payload)
      .pipe(map((resp: ApiResp) => this.handleResp(resp).response));
  }

  logout(): Observable<boolean> {
    return this.httpClient
      .post<ApiResp>('/auth/user/logout', {})
      .pipe(map((resp) => this.booleanResp(resp)));
  }
}
