/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResp, FieldError } from '@app/types/global.type';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of } from 'rxjs';

export interface FormResp {
  status: 0 | 1;
  errors: FieldError[];
}

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(
    protected httpClient: HttpClient,
    protected toastr: ToastrService,
  ) {}

  postApi(url: string, payload: any, params?: any) {
    const options = { params };
    return this.httpClient.post(url, payload, options).pipe(
      map((resp) => resp),
      catchError((err) => of(err)),
    );
  }

  booleanResp(resp: ApiResp): boolean {
    if (resp.status) this.toastr.success(resp.message);
    else this.toastr.error(resp.message);

    return !!resp.status;
  }

  handleResp(resp: ApiResp) {
    if (resp.status) this.toastr.success(resp.message);
    else this.toastr.error(resp.message);

    return resp;
  }

  handleFormResp(resp: ApiResp) {
    if (resp.status) this.toastr.success(resp.message);
    else this.toastr.error(resp.fieldErrors?.[0].message ?? resp.message);

    return resp;
  }

  formResp(resp: ApiResp): FormResp {
    if (resp.status) this.toastr.success(resp.message);
    else this.toastr.error(resp.message);

    return { status: resp.status, errors: resp.fieldErrors ?? [] };
  }
}
